import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { stringifyUrl } from 'query-string';

import { CREATE_CHECKOUT_SESSION_URL } from '../constants';
import { getDemoPayload } from '../utils';
import Loading from '../components/Loading';

import styles from './Future.module.scss';
import debounce from 'lodash.debounce';

const getDesktopMaxWidth = () => {
  if (isTablet) {
    return '95%';
  }

  if (isMobileOnly) {
    return '100%';
  }

  return '1200px';
};

const ThreeDS = () => {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkoutURL, setCheckoutURL] = useState();
  const [postalCode, setPostalCode] = useState('');
  const [brand, setBrand] = useState('sheiseido');
  const view = useSelector((state) => state.ui.view);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetCheckoutSession = useCallback(
    debounce(
      (options) => {
        const url = stringifyUrl({
          url: CREATE_CHECKOUT_SESSION_URL,
          query: { live: 1 },
        });

        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            getDemoPayload({
              brand: options.brand,
              options: {
                shippingInfo: {
                  feeAmount: 0,
                  feeCurrency: 'JPY',
                  address: {
                    line1: '北青山 3-6-7',
                    line2: '青山パラシオタワー 11階',
                    subLocality: '',
                    locality: '港区',
                    administrativeArea: '東京都',
                    postalCode: options.postalCode,
                    country: 'JP',
                  },
                },
              },
            })
          ),
        })
          .then((res) => res.json())
          .then((session) => {
            setCheckoutURL(session.checkoutURL);
            setLoading(false);
          });
      },
      500,
      { leading: false }
    ),
    []
  );

  useEffect(() => {
    const numberOnlyPostalCode = postalCode.replace(/[^\d]/g, '');

    if (/\d\d\d\d\d\d\d/.test(numberOnlyPostalCode)) {
      setReady(true);
      setLoading(true);

      debouncedGetCheckoutSession({
        brand,
        postalCode: `${numberOnlyPostalCode.slice(
          0,
          3
        )}-${numberOnlyPostalCode.slice(3)}`,
      });
    }
  }, [debouncedGetCheckoutSession, brand, postalCode]);

  useEffect(() => {
    if (checkoutURL) {
      console.log(checkoutURL);
    }
  }, [checkoutURL]);

  return (
    <div
      className={styles.content}
      style={{
        maxWidth: view === 'mobile' ? '375px' : getDesktopMaxWidth(),
        borderRadius: view === 'mobile' ? 32 : 8,
      }}
    >
      <h2>3DS Live Session</h2>
      <div>
        <label>Postal Code</label>
        <input
          type="text"
          value={postalCode}
          onChange={(event) => setPostalCode(event.target.value)}
          placeholder="105-0003"
        ></input>
        <p className={styles.note}>
          Make sure the code matches your live account info.
        </p>
      </div>
      <div>
        <label>Amount</label>
        <div>
          <label>
            <input
              type="radio"
              name="brand"
              value="sheiseido"
              checked={brand === 'sheiseido'}
              onChange={(event) => setBrand('sheiseido')}
            />{' '}
            ¥200
          </label>
          <label>
            <input
              type="radio"
              name="brand"
              value="sheiseido"
              checked={brand === 'sheiseido2'}
              onChange={(event) => setBrand('sheiseido2')}
            />{' '}
            ¥600
          </label>
        </div>
        <p className={styles.note}>
          Over 300yen, and use 'Auto' will go to 3DS flow.
        </p>
      </div>
      <div className={styles.actions}>
        {loading || !checkoutURL ? (
          ready && <Loading />
        ) : (
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={stringifyUrl({
                url: checkoutURL,
                query: {
                  'feat-three-ds': 1,
                  demo: undefined,
                },
              })}
              className={styles.btn}
            >
              Auto
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href={stringifyUrl({
                url: checkoutURL,
                query: {
                  'feat-three-ds': 1,
                  'feat-force-three-ds': 1,
                  demo: undefined,
                },
              })}
              className={styles.btn}
            >
              Required
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href={stringifyUrl({
                url: checkoutURL,
                query: {
                  'feat-three-ds': 1,
                  'feat-force-no-three-ds': 1,
                  demo: undefined,
                },
              })}
              className={styles.btn}
            >
              NotRequired
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThreeDS;
