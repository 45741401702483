const hosono = [
  {
    name: 'トート前P付き',
    productDescription: '帆布バッグの工房HOSONO',
    amount: 6600,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0055/3720/2246/products/ho-03019_0001_110x110@2x.jpg',
    ],
  },
  {
    name: 'リュックMN',
    productDescription: '帆布バッグの工房HOSONO',
    amount: 29700,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0055/3720/2246/products/ho-01001_01s_e929a89a-ec85-4b82-8a5f-5acd346cba07_110x110@2x.jpg',
    ],
  },
  {
    name: '大きさの変わるショルダーS',
    productDescription: '帆布バッグの工房HOSONO',
    amount: 12650,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0055/3720/2246/products/ho-04006_01s_f6f044bf-7287-4271-b04a-d8f5b1d75f31_110x110@2x.jpg',
    ],
  },
  {
    name: 'ブックカバー',
    productDescription: '帆布バッグの工房HOSONO',
    amount: 1650,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0055/3720/2246/products/ho-05002_01s_57f08396-3b97-4e2c-ae98-552674b47fee_110x110@2x.jpg',
    ],
  },
];

export default hosono;
