import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { stringifyUrl } from 'query-string';

import { CREATE_CHECKOUT_SESSION_URL } from '../constants';
import { getDemoPayload } from '../utils';
import Loading from '../components/Loading';

import styles from './Future.module.scss';

const getDesktopMaxWidth = () => {
  if (isTablet) {
    return '95%';
  }

  if (isMobileOnly) {
    return '100%';
  }

  return '1200px';
};

const SSOGoogle = () => {
  const [loading, setLoading] = useState(true);
  const [checkoutURL, setCheckoutURL] = useState();
  const view = useSelector((state) => state.ui.view);

  useEffect(() => {
    setLoading(true);

    const url = stringifyUrl({
      url: CREATE_CHECKOUT_SESSION_URL,
      query: { live: 1 },
    });

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getDemoPayload({ brand: '', options: {} })),
    })
      .then((res) => res.json())
      .then((session) => {
        setCheckoutURL(session.checkoutURL);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (checkoutURL) {
      console.log(checkoutURL);
    }
  }, [checkoutURL]);

  return (
    <div
      className={styles.content}
      style={{
        maxWidth: view === 'mobile' ? '375px' : getDesktopMaxWidth(),
        borderRadius: view === 'mobile' ? 32 : 8,
      }}
    >
      <h2>Google SSO Test</h2>
      <div className={styles.actions}>
        {loading || !checkoutURL ? (
          <Loading />
        ) : (
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={stringifyUrl({
                url: checkoutURL,
                query: {
                  'feat-sso-google': 1,
                  demo: undefined,
                },
              })}
              className={styles.btn}
            >
              Go To Checkout
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SSOGoogle;
