import { Router } from '@reach/router';

import Home from './Home';
import QR from './QR';
import PreferApp from './future/PreferApp';
import ThreeDS from './future/ThreeDS';
import SSOGoogle from './future/SSOGoogle';
import Helper from './helpers/DevHelper';

import styles from './App.module.scss';

function App() {
  return (
    <Router primary={false} className={styles.content}>
      <Home path="/" />
      <QR path="/qr" />
      <PreferApp type="test" path="/future/prefer-app-test" />
      <PreferApp type="live" path="/future/prefer-app-live" />
      <ThreeDS path="/future/three-ds" />
      <SSOGoogle path="/future/sso-google" />
      <Helper path="/helper/dev" />
    </Router>
  );
}

export default App;
