const sheiseido = [
  {
    name: 'ハイドレーティング クリーム',
    productDescription:
      'あなたの肌に、保湿体力を。わきあがるようなつやとうるおいが際立つ肌へ導くモイスチャライザー※、登場。',
    amount: 100,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://brand.shiseido.co.jp/dw/image/v2/BBSC_PRD/on/demandware.static/-/Sites-itemmaster_shiseido_jp/default/dw56f4cab7/products-02/18285/18285_S_01.jpg?sw=1000&sh=1000&sm=fit',
    ],
  },
  {
    name: 'ディフェンス ＋ ブライトニング キット',
    productDescription:
      'アルティミューン」「ソフナー」「モイスチャライザー」の3品を体感できる生命感あふれるつややかな肌へ導くD＆Rトライアルキット。',
    amount: 100,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://brand.shiseido.co.jp/dw/image/v2/BBSC_PRD/on/demandware.static/-/Sites-itemmaster_shiseido_jp/default/dw376fd025/products-02/18776/18776_J_01.jpg?sw=1000&sh=1000&sm=fit',
    ],
  },
  {
    name: 'トータル Rクリームe',
    productDescription:
      '肌の美を紡ぐ大切な夜に。じっくり優美な肌へ導くクリーム',
    amount: 100,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://brand.shiseido.co.jp/dw/image/v2/BBSC_PRD/on/demandware.static/-/Sites-itemmaster_shiseido_jp/default/dw8d0aeb2b/products/13921/13921_01.jpg?sw=1000&sh=1000&sm=fit',
    ],
  },
  {
    name: 'パワーブライトニング マスク',
    productDescription: 'イオンフォーステクノロジー採用の薬用美白マスク',
    amount: 100,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://brand.shiseido.co.jp/dw/image/v2/BBSC_PRD/on/demandware.static/-/Sites-itemmaster_shiseido_jp/default/dwb68771af/products/10447/10447_S_01.jpg?sw=1000&sh=1000&sm=fit',
    ],
  },
  {
    name: 'スキンケアコットン',
    productDescription: 'お手入れをより効果的に。スキンケアのためのコットン。',
    amount: 100,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://brand.shiseido.co.jp/dw/image/v2/BBSC_PRD/on/demandware.static/-/Sites-itemmaster_shiseido_jp/default/dw87692ba6/products/4514254991732/4514254991732_01.jpg?sw=1000&sh=1000&sm=fit',
    ],
  },
  {
    name: 'ブライトニング スポットコントロール ファンデーション(レフィル)',
    productDescription:
      'シミの種にいち速く。美白深める薬用・美透白※ファンデーション',
    amount: 100,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://brand.shiseido.co.jp/dw/image/v2/BBSC_PRD/on/demandware.static/-/Sites-itemmaster_shiseido_jp/default/dw40e6c7f0/products/10453/10453_01.jpg?sw=1000&sh=1000&sm=fit',
    ],
  },
];

export default sheiseido;
