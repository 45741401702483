const plst = [
  {
    name: 'コットンブレンドチノハイウエストマーメードスカート',
    productDescription: 'スカート',
    amount: 8990,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://image.plst.com/PL/ST3/jp/imagesgoods/700628/item/jpgoods_53_700628.jpg?width=300',
    ],
  },
  {
    name: 'ウォッシャブルリブセーター',
    productDescription: 'ニット',
    amount: 6291,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://image.plst.com/PL/ST3/jp/imagesgoods/700035/item/jpgoods_32_700035.jpg?width=300',
    ],
  },
  {
    name: 'AndreaCardone キャンディーカラーバッグ',
    productDescription: 'バッグ・鞄',
    amount: 15000,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://image.plst.com/PL/ST3/jp/imagesgoods/700461/item/jpgoods_32_700461.jpg?width=300',
    ],
  },
  {
    name: 'トリプルフープピアス',
    productDescription: 'アクセサリー',
    amount: 1990,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://image.plst.com/PL/ST3/jp/imagesgoods/700185/item/jpgoods_80_700185.jpg?width=300',
    ],
  },
];

export default plst;
