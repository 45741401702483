import { createSlice } from '@reduxjs/toolkit';
import { isMobileOnly } from 'react-device-detect';

export const initialState = {
  view: isMobileOnly ? 'mobile' : 'desktop',
};

const UISlice = createSlice({
  name: '@@checkout-demo/ui',
  initialState,
  reducers: {
    updateUIPairs(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateUIPairs } = UISlice.actions;

export default UISlice.reducer;
