const hm = [
  {
    name: 'フィットワンピース',
    productDescription: 'ブラック',
    amount: 3999,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F3a%2Fd1%2F3ad1fc2c934c5c62090775326bc3f5e8a14d1240.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
    ],
  },
  {
    name: 'ワイドパンツ',
    productDescription: 'グレー／ヘリンボーンパターン',
    amount: 4999,
    currency: 'JPY',
    quantity: 2,
    images: [
      'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F48%2Fe5%2F48e59f3da46ee282eb42c7c873c630bf2b45581f.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
    ],
  },
  {
    name: 'センターシームレギンス',
    productDescription: 'ブラック',
    amount: 2499,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2Ff2%2Fce%2Ff2cefc5917f638030f594aea1a5cc5b56fddee50.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
    ],
  },
  {
    name: 'プリントトップス 5枚セット',
    productDescription: 'パープル／パウ・パトロール',
    amount: 4999,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F73%2F4a%2F734acefbfd47f8b4ff51d708d96c1eb09d57907f.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]',
    ],
  },
];

export default hm;
