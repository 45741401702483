import myAxios from '../utils/my-axios';

const setup = (store) => {
  let token = '';

  const handleChange = () => {
    const newToken = store.getState()?.auth?.accessToken;

    if (newToken != null && newToken !== token) {
      token = newToken;
    }
  };

  store.subscribe(handleChange);

  myAxios.interceptors.request.use(
    (config) => {
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

export default setup;
