import Header from './Header';

import styles from './Layout.module.scss';

const Layout = ({ title, children }) => {
  return (
    <div className={styles.root}>
      <div className={styles['header-wrapper']}>
        <Header />
      </div>
      <div className={styles['main-wrapper']}>
        <main className={styles.main}>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
