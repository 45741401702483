import Lottie from 'react-lottie';

import transactionLoading from './transaction-loading.json';

const Loading = () => (
  <div>
    <Lottie
      options={{
        autoplay: true,
        animationData: transactionLoading,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={125}
      width={125}
      isClickToPauseDisabled
    />
  </div>
);

export default Loading;
