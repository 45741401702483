import { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import QRCode from 'react-qr-code';
import cx from 'classnames';

import QRHeroDefault from '../src/assets/qr-hero-default.jpg';
import Logo from '../src/assets/logo-color.svg';
import { CREATE_CHECKOUT_SESSION_URL } from './constants';
import { getDemoPayload } from './utils';
import Loading from './components/Loading';

import styles from './QR.module.scss';

const themes = {
  default: {
    title: 'Smartpay',
    logo: Logo,
    hero: QRHeroDefault,
  },
  ikea: {
    title: 'IKEA',
    logo: `${window.location.origin}/logo-ikea.png`,
    hero: 'https://www.ikea.com/images/64/34/643471d32ab2718df8ce92a3d9dfd860.png?f=xxxl',
  },
  uniqlo: {
    title: 'UNIQLO',
    logo: `${window.location.origin}/logo-uniqlo.png`,
    hero: 'https://moneyinc.com/wp-content/uploads/2018/11/Uniqlo.jpeg',
  },
  muji: {
    title: '無印良品',
    logo: `${window.location.origin}/logo-muji.png`,
    hero: 'https://media.timeout.com/images/105780551/image.jpg',
  },
  plst: {
    title: 'PLST',
    logo: `${window.location.origin}/logo-plst.png`,
    hero: 'https://im.uniqlo.com/global-cms/spa/res54ecc3c0e97b40bfa632f38f224d38eefr.jpg',
  },
  hm: {
    title: 'H&M',
    logo: `${window.location.origin}/logo-hm.png`,
    hero: 'https://lp2.hm.com/hmgoepprod?source=url[https://www2.hm.com/content/dam/men_s06/august-2022/3106/3106-1x1-blank-staples.jpg]&scale=size[795]&sink=format[jpeg],quality[80]',
  },
  myprotein: {
    title: 'MYPROTEIN',
    logo: `https://cdn.knoji.com/images/logo/myprotein.jpg`,
    hero: 'https://m.media-amazon.com/images/I/81k8jbTeLkL._AC_SL1500_.jpg',
  },
};

const QR = () => {
  const location = useLocation();
  const params = parse(location.search);
  const paramString = Object.keys(params)
    .filter((k) => !!params[k])
    .map((k) => `${k}=${params[k]}`)
    .join('&');
  const [sessionIdWithSignature, setSessionIdWithSignature] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    fetch(CREATE_CHECKOUT_SESSION_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        getDemoPayload({
          brand: params.brand,
          options: { promotionCode: 'SPRINGSALE2022' },
        })
      ),
    })
      .then((res) => res.json())
      .then((session) => {
        setIsLoading(false);
        setSessionIdWithSignature(session.url.split('/')[3]);
      });
  }, [paramString, params.brand]);

  let deepLink = `smartpay://checkout/${sessionIdWithSignature}`;

  const _brand = Object.keys(themes).includes(params.brand)
    ? params.brand
    : 'default';

  if (_brand) {
    deepLink += `&logo=${themes[_brand].logo}`;
  }

  if (isLoading) {
    return (
      <div className={cx(styles.wrapper, styles.center)}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={cx(styles.wrapper, styles[_brand])}>
      {_brand && (
        <img
          className={styles.logo}
          src={themes[_brand]?.logo}
          alt={themes[_brand]?.title}
        />
      )}
      <div className={cx(styles['brand-wrapper'], styles[_brand])}>
        <div className={styles.left}>
          <img src={themes[_brand]?.hero} alt={themes[_brand]?.title} />
        </div>
        <div className={styles.right}>
          <QRCode value={deepLink} className={styles.qr} size={200} />
        </div>
      </div>
    </div>
  );
};

export default QR;
