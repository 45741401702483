const ikea = [
  {
    name: 'FLISAT フリサット',
    productDescription: '子ども用スツール',
    amount: 1999,
    currency: 'JPY',
    quantity: 2,
    images: [
      'https://www.ikea.com/jp/ja/images/products/flisat-childrens-stool__0727952_pe735964_s5.jpg?f=xxs',
    ],
  },
  {
    name: 'TROFAST トロファスト',
    productDescription: '収納ボックス, イエロー',
    amount: 300,
    currency: 'JPY',
    quantity: 11,
    images: [
      'https://www.ikea.com/jp/ja/images/products/trofast-storage-box-yellow__0624258_pe691761_s5.jpg?f=xxs',
    ],
  },
  {
    name: 'FLISAT フリサット',
    productDescription: '子ども用テーブル',
    amount: 6999,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://www.ikea.com/jp/ja/images/products/flisat-childrens-table__0735831_pe740206_s5.jpg?f=xxs',
    ],
  },
  {
    name: 'TROFAST トロファスト',
    productDescription:
      '収納コンビネーション, ライトホワイトステインパイン ホワイト/ブラック',
    amount: 8990,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://www.ikea.com/jp/ja/images/products/trofast-storage-combination-with-boxes-light-white-stained-pine-white-black__0351182_pe547508_s5.jpg?f=xxs',
    ],
  },
];

export default ikea;