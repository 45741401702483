import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { stringifyUrl } from 'query-string';
import { parse } from 'query-string';

import { CREATE_CHECKOUT_SESSION_URL } from '../constants';
import { getDemoPayload } from '../utils';
import Loading from '../components/Loading';

import styles from './DevHelper.module.scss';
import debounce from 'lodash.debounce';
import { useLocation } from '@reach/router';

const featureFlags = [
  'feat-login-via-app',
  'feat-bank-direct',
  'feat-force-prefer-platform-prompt',
  'feat-three-ds',
  'feat-force-three-ds',
  'feat-force-no-three-ds',
  'feat-sso-google',
  'feat-sso-apple',
];

const getDesktopMaxWidth = () => {
  if (isTablet) {
    return '95%';
  }

  if (isMobileOnly) {
    return '100%';
  }

  return '1200px';
};

const replaceState = (query) => {
  window.history.replaceState(
    {},
    '',
    stringifyUrl({
      url: document.location.href,
      query,
    })
  );
};

const DevHelper = () => {
  const location = useLocation();
  const params = parse(location.search);

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkoutURL, setCheckoutURL] = useState();
  const [decoratedURL, setDecoratedURL] = useState();
  const [postalCode, setPostalCode] = useState('105-0003');
  const [baseUrl, setBaseUrl] = useState(
    params.base === 're'
      ? 'https://checkout.smartpay.re'
      : params.base === 'co'
      ? 'https://checkout.smartpay.co'
      : 'http://localhost:3000'
  );
  const [postfix, setPostfix] = useState(params.postfix || '');
  const [testOrLive, setTestOrLive] = useState(params.live ? 'live' : 'test');
  const [brand, setBrand] = useState('sheiseido');
  const [token, setToken] = useState(!!params.token);
  const [captureMethod, setCaptureMethod] = useState(
    params.manual ? 'manual' : 'automatic'
  );
  const [flags, setFlags] = useState(
    featureFlags.reduce((accum, feat) => {
      return {
        ...accum,
        ...(feat in params ? { [feat]: '1' } : {}),
      };
    }, {})
  );

  const view = useSelector((state) => state.ui.view);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetCheckoutSession = useCallback(
    debounce(
      (options) => {
        const url = stringifyUrl({
          url: CREATE_CHECKOUT_SESSION_URL,
          query: { ...(testOrLive === 'live' ? { live: 1 } : {}) },
        });

        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            getDemoPayload({
              brand: options.brand,
              options: {
                captureMethod,
                shippingInfo: {
                  feeAmount: 0,
                  feeCurrency: 'JPY',
                  address: {
                    line1: '北青山 3-6-7',
                    line2: '青山パラシオタワー 11階',
                    subLocality: '',
                    locality: '港区',
                    administrativeArea: '東京都',
                    postalCode: options.postalCode,
                    country: 'JP',
                  },
                },
                ...(options.token ? { mode: 'token' } : {}),
              },
            })
          ),
        })
          .then((res) => res.json())
          .then((session) => {
            setCheckoutURL(session.checkoutURL);
            setLoading(false);
          });
      },
      500,
      { leading: false }
    ),
    [testOrLive]
  );

  useEffect(() => {
    const numberOnlyPostalCode = postalCode.replace(/[^\d]/g, '');

    if (/\d\d\d\d\d\d\d/.test(numberOnlyPostalCode)) {
      setReady(true);
      setLoading(true);

      debouncedGetCheckoutSession({
        brand,
        token,
        postalCode: `${numberOnlyPostalCode.slice(
          0,
          3
        )}-${numberOnlyPostalCode.slice(3)}`,
      });
    }
  }, [debouncedGetCheckoutSession, brand, postalCode, token]);

  useEffect(() => {
    if (!checkoutURL) {
      return;
    }

    try {
      const url = new URL(checkoutURL);
      const baseUrlObj = new URL(baseUrl);

      url.protocol = baseUrlObj.protocol;
      url.host = baseUrlObj.host;

      const query = {
        demo: undefined,
      };

      featureFlags.forEach((key) => {
        query[key] = !!flags[key] ? '1' : undefined;
      });

      setDecoratedURL(
        stringifyUrl({
          url: url.toString(),
          query,
        }) + postfix
      );
    } catch (e) {}
  }, [baseUrl, checkoutURL, flags, postfix]);

  useEffect(() => {
    if (decoratedURL) {
      console.log(decoratedURL);
    }
  }, [decoratedURL]);

  return (
    <div
      className={styles.content}
      style={{
        maxWidth: view === 'mobile' ? '375px' : getDesktopMaxWidth(),
        borderRadius: view === 'mobile' ? 32 : 8,
      }}
    >
      <h2>Local Test Helper</h2>
      <div className={styles.field}>
        <label>Postal Code</label>
        <input
          type="text"
          value={postalCode}
          onFocus={(event) => event.target.select()}
          onChange={(event) => setPostalCode(event.target.value)}
          placeholder="105-0003"
        ></input>
        <p className={styles.note}>
          Make sure the code matches your live account info.
        </p>
      </div>
      <div className={styles.field}>
        <label>Test/Live</label>
        <div className={styles.radios}>
          <label>
            <input
              type="radio"
              name="test_live"
              value="test"
              checked={testOrLive === 'test'}
              onChange={(event) => {
                replaceState({ live: undefined });
                setTestOrLive('test');
              }}
            />{' '}
            Test
          </label>
          <label>
            <input
              type="radio"
              name="test_live"
              value="live"
              checked={testOrLive === 'live'}
              onChange={(event) => {
                replaceState({ live: 1 });
                setTestOrLive('live');
              }}
            />{' '}
            Live
          </label>
        </div>
      </div>

      <div className={styles.field}>
        <label>Token?</label>
        <div className={styles.radios}>
          <label>
            <input
              type="radio"
              name="token"
              value="no"
              checked={token}
              onChange={(event) => {
                replaceState({ token: 1 });
                setToken(true);
              }}
            />{' '}
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="token"
              value="automatic"
              checked={!token}
              onChange={(event) => {
                replaceState({ token: undefined });
                setToken(false);
              }}
            />{' '}
            No
          </label>
        </div>
      </div>
      <div className={styles.field}>
        <label>Capture Method</label>
        <div className={styles.radios}>
          <label>
            <input
              type="radio"
              name="capture"
              value="manual"
              checked={captureMethod === 'manual'}
              onChange={(event) => {
                replaceState({ manual: 1 });
                setCaptureMethod('manual');
              }}
            />{' '}
            Manual
          </label>
          <label>
            <input
              type="radio"
              name="capture"
              value="automatic"
              checked={captureMethod === 'automatic'}
              onChange={(event) => {
                replaceState({ manual: undefined });
                setCaptureMethod('automatic');
              }}
            />{' '}
            Automatic
          </label>
        </div>
      </div>
      <div className={styles.field}>
        <label>Amount</label>
        <div className={styles.radios}>
          <label>
            <input
              type="radio"
              name="brand"
              value="sheiseido"
              checked={brand === 'sheiseido'}
              onChange={(event) => setBrand('sheiseido')}
            />{' '}
            ¥200
          </label>
          <label>
            <input
              type="radio"
              name="brand"
              value="sheiseido"
              checked={brand === 'sheiseido2'}
              onChange={(event) => setBrand('sheiseido2')}
            />{' '}
            ¥600
          </label>
        </div>
        <p className={styles.note}>Over 300yen will go to 3DS flow.</p>
      </div>
      <div className={styles.field}>
        <label>Base URL</label>
        <input
          type="text"
          value={baseUrl}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            const v = event.target.value;

            if (
              [
                'https://checkout.smartpay.re',
                'https://checkout.smartpay.co',
              ].includes(v)
            ) {
              replaceState({ base: v.slice(-2) });
            }

            setBaseUrl(event.target.value);
          }}
          list="base-url-list"
          placeholder="https://checkout.smartpay.re"
        ></input>
        <datalist id="base-url-list">
          <option value="https://checkout.smartpay.re"></option>
          <option value="https://checkout.smartpay.co"></option>
          <option value="http://localhost:3000"></option>
        </datalist>
      </div>
      <div className={styles.field}>
        <label>Custom URL Postfix</label>
        <input
          type="text"
          value={postfix}
          onFocus={(event) => event.target.select()}
          onChange={(event) => {
            replaceState({ postfix: event.target.value || undefined });
            setPostfix(event.target.value);
          }}
          placeholder=""
        ></input>
      </div>
      <div className={styles.field}>
        <label>Features</label>
        <div>
          {featureFlags.map((feature) => {
            return (
              <div>
                <label>
                  <input
                    type="checkbox"
                    name={feature}
                    checked={flags[feature]}
                    value="1"
                    onChange={(event) => {
                      setFlags({ ...flags, [feature]: event.target.checked });
                      replaceState({
                        [feature]: event.target.checked ? '1' : undefined,
                      });
                    }}
                  ></input>
                  {feature}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.actions}>
        {loading || !decoratedURL ? (
          ready && <Loading />
        ) : (
          <>
            <div className={styles.preview}>
              {decoratedURL.replace(/\?/g, '\n?').replace(/&/g, '\n&')}
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href={decoratedURL}
                className={styles.btn}
              >
                Go
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DevHelper;
