import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { stringifyUrl } from 'query-string';

import { CREATE_CHECKOUT_SESSION_URL } from '../constants';
import { getDemoPayload } from '../utils';
import Loading from '../components/Loading';

import styles from './Future.module.scss';

const getDesktopMaxWidth = () => {
  if (isTablet) {
    return '95%';
  }

  if (isMobileOnly) {
    return '100%';
  }

  return '1200px';
};


const PreferApp = ({type}) => {
  const isLive = type === 'live';
  const [loading, setLoading] = useState(true);
  const [checkoutURL, setCheckoutURL] = useState();
  const view = useSelector((state) => state.ui.view);

  useEffect(() => {
    setLoading(true);

    const url = isLive ?
      stringifyUrl({
        url: CREATE_CHECKOUT_SESSION_URL,
        query: { live: 1 }
      }) : CREATE_CHECKOUT_SESSION_URL;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        getDemoPayload({brand:'', options: {}})
      ),
    })
      .then((res) => res.json())
      .then((session) => {
        setCheckoutURL(session.checkoutURL);
        setLoading(false);
      });
  }, [isLive]);

  useEffect(() => {
    if (checkoutURL) {
      console.log(checkoutURL);
    }
  }, [checkoutURL]);

  return (
      <div
        className={styles.content}
        style={{
          maxWidth: view === 'mobile' ? '375px' : getDesktopMaxWidth(),
          borderRadius: view === 'mobile' ? 32 : 8,
        }}
      >
        <h2>{isLive ? 'Live Session' : 'Test Session(test account required)'}</h2>
        <div className={styles.actions}>
          {loading || !checkoutURL ? (
            <Loading />
          ) : (
            <div>
              <a target="_blank" rel="noreferrer" href={stringifyUrl({
                url: checkoutURL,
                query: {
                  'feat-prefer-app-prompt': 1,
                  'feat-one-link': 1
                }
              })} className={styles.btn}>Go To Checkout</a>
            </div>
          )}
        </div>
      </div>
  );
};

export default PreferApp;
