const sheiseido = {
  'merchant-name': 'Sheiseido',
  logo: 'https://www.shiseido.co.jp/shared/top/img_v2/logo.gif',
};

const ikea = {
  'merchant-name': 'IKEA',
  logo: `https://demo.smartpay.co/logo-ikea.png`,
};

const uniqlo = {
  'merchant-name': 'UNIQLO',
  logo: 'https://demo.smartpay.co/logo-uniqlo.png',
};

const hosono = {
  'merchant-name': 'HOSONO',
  logo: 'https://cdn.shopify.com/s/files/1/0055/3720/2246/files/seiki_hosono_logo_s_360x.png',
};

const denis = {
  'merchant-name': 'DENIS',
  logo: 'https://cdn.shopify.com/s/files/1/0002/9720/5796/files/PA01240851_3ddd437e-17a5-4dfe-8d62-a1e0a227e8df_140x@2x.png',
};

const muji = {
  'merchant-name': 'MUJI',
  logo: `https://demo.smartpay.co/logo-muji.png`,
};

const plst = {
  'merchant-name': 'PLST',
  logo: `https://demo.smartpay.co/logo-plst.png`,
};

const hm = {
  'merchant-name': 'H&M',
  logo: `https://demo.smartpay.co/logo-hm.png`,
};

const myprotein = {
  'merchant-name': 'MYPROTEIN',
  logo: 'https://cdn.knoji.com/images/logo/myprotein.jpg',
};

export const paramsByBrand = {
  sheiseido,
  ikea,
  uniqlo,
  hosono,
  denis,
  muji,
  plst,
  hm,
  myprotein,
};
