import { defaultPayload, lineItems } from '../constants/payload';

export const getDemoPayload = ({ brand = 'sheiseido', options = {} }) => {
  const _brand = Object.keys(lineItems).includes(brand) ? brand : 'sheiseido';

  return {
    ...defaultPayload,
    ...options,
    items: lineItems[_brand],
  };
};
