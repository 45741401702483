const muji = [
  {
    name: 'エイジングケア薬用リンクルケアクリームマスク',
    productDescription: '80g',
    amount: 1990,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://www.muji.com/public/media/img/item/4550344294413_1260.jpg',
    ],
  },
  {
    name: '敏感肌用日焼け止めミルク　ＳＰＦ２７',
    productDescription: 'ＳＰＦ２７・ＰＡ＋＋　１５０ｍｌ',
    amount: 1490,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://www.muji.com/public/media/img/item/4547315252589_1260.jpg',
    ],
  },
  {
    name: '不揃い　ホワイトチョコがけいちご',
    productDescription: '50g',
    amount: 290,
    currency: 'JPY',
    quantity: 4,
    images: [
      'https://www.muji.com/public/media/img/item/4547315283415_1260.jpg',
    ],
  },
  {
    name: 'ひざ掛けにもなるピロー',
    productDescription: '黒　約７０×１２０ｃｍ',
    amount: 1890,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://www.muji.com/public/media/img/item/4550344594377_1260.jpg',
    ],
  },
];

export default muji;
