const myprotein = [
  {
    name: 'はじめての方限定！ホエイプロテイン1㎏＋シェイカーお試しセット',
    productDescription:
      'キャンペーン価格2980円は、「2980」コードを入力しレジの最終画面で適用されます',
    amount: 5390,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://static.thcdn.com/images/large/webp/productimg/1600/1600/12202486-1454909900245390.jpg',
    ],
  },
  {
    name: 'ゴールデン ホエイ プロテイン',
    productDescription: '贅沢な味わいのホエイプロテイン',
    amount: 6390,
    currency: 'JPY',
    quantity: 2,
    images: [
      'https://static.thcdn.com/images/large/webp/productimg/1600/1600/12741539-1694853679482508.jpg',
    ],
  },
  {
    name: 'プロテイン ブラウニー',
    productDescription: '持ち運びに便利な高タンパク質ブラウニー',
    amount: 4490,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://static.thcdn.com/images/large/webp/productimg/1600/1600/11094929-9174824868519167.jpg',
    ],
  },
];

export default myprotein;
