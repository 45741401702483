const uniqlo = [
  {
    name: 'オーバーシャツ ジャケット',
    productDescription:
      '軽く羽織れる便利な1枚。品のある素材感にミリタリーテイストなデザインで個性をプラス。',
    amount: 4990,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://image.uniqlo.com/UQ/ST3/AsianCommon/imagesgoods/447463/item/goods_32_447463.jpg?width=402',
    ],
  },
  {
    name: 'KIDS ファーリーフリースジャケット（長袖）',
    productDescription:
      '環境に配慮したリサイクル素材を使用。ふわふわの素材が柔らかく暖かく包み込む。',
    amount: 1500,
    currency: 'JPY',
    quantity: 2,
    images: [
      'https://image.uniqlo.com/UQ/ST3/AsianCommon/imagesgoods/439328/item/goods_31_439328.jpg?width=402',
    ],
  },
  {
    name: 'KIDS ヒートテックUネックT（9分袖）',
    productDescription:
      '暖かいのにすっきり薄手で着ぶくれしにくい。よりなめらかで素肌に心地いいインナーに。',
    amount: 590,
    currency: 'JPY',
    quantity: 4,
    images: [
      'https://image.uniqlo.com/UQ/ST3/AsianCommon/imagesgoods/439412/item/goods_00_439412.jpg?width=402',
    ],
  },
  {
    name: 'コンビハダギ（2枚組）',
    productDescription:
      '産まれたばかりの赤ちゃんをやさしく包む工夫が詰まった肌着。裾が開いていて動きやすく、おむつ替えもラク。',
    amount: 1500,
    currency: 'JPY',
    quantity: 2,
    images: [
      'https://image.uniqlo.com/UQ/ST3/AsianCommon/imagesgoods/440352/item/goods_50_440352.jpg?width=402',
    ],
  },
];

export default uniqlo;
