import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isCouponAppliedAutomatically: true,
};

const optionSlice = createSlice({
  name: '@@checkout-demo/option',
  initialState,
  reducers: {
    updateOptionPairs(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateOptionPairs } = optionSlice.actions;

export default optionSlice.reducer;
