const denis = [
  {
    name: 'プロテイン シャンプー 290ML',
    productDescription:
      '根元が立ち上がり非常に軽い仕上がり、乾かす事でより艶が増します。',
    amount: 2750,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0002/9720/5796/products/Psp290_front_200x.jpg',
    ],
  },
  {
    name: 'ナチュラル シャンプー 290ML',
    productDescription: '驚くほど収まるのでスタイリングが非常に楽になります。',
    amount: 2750,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0002/9720/5796/products/Nsp_290_front_200x.jpg',
    ],
  },
  {
    name: 'エス シャンプー 290ML',
    productDescription:
      '必要を残して不要を取る”デニスの基本姿勢を継承したCOOLシャンプー誕生。',
    amount: 2750,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0002/9720/5796/products/SSsp_290_front_200x.jpg',
    ],
  },
  {
    name: 'デニスシャンプー 専用ポンプ 290㎖',
    productDescription:
      '各種SHAMPOOの290㎖ボトルに対応したオリジナルのポンプ。',
    amount: 220,
    currency: 'JPY',
    quantity: 1,
    images: [
      'https://cdn.shopify.com/s/files/1/0002/9720/5796/products/caa01cba697d0a76310643dde9503059_200x.png',
    ],
  },
];

export default denis;
