import plst from './plst';
import denis from './denis';
import sheiseido from './sheiseido';
import sheiseido2 from './sheiseido-2';
import ikea from './ikea';
import uniqlo from './uniqlo';
import muji from './muji';
import hosono from './hosono';
import hm from './hm';
import myprotein from './myprotein';

export const lineItems = {
  sheiseido,
  sheiseido2,
  plst,
  ikea,
  uniqlo,
  hosono,
  denis,
  muji,
  hm,
  myprotein,
};

export const defaultPayload = {
  customerInfo: {
    accountAge: 20,
    firstName: '田中',
    lastName: '太郎',
    firstNameKana: 'タナカ',
    lastNameKana: 'タロウ',
    address: {
      line1: '北青山 3-6-7',
      line2: '青山パラシオタワー 11階',
      subLocality: '',
      locality: '港区',
      administrativeArea: '東京都',
      postalCode: '107-0061',
      country: 'JP',
    },
    dateOfBirth: '1985-06-30',
    legalGender: 'male',
  },
  shippingInfo: {
    address: {
      line1: '北青山 3-6-7',
      line2: '青山パラシオタワー 11階',
      subLocality: '',
      locality: '港区',
      administrativeArea: '東京都',
      postalCode: '107-0061',
      country: 'JP',
    },
    feeAmount: 0,
    feeCurrency: 'JPY',
  },
  // Your internal reference of the order
  reference: 'order_ref_1234567',
  // Callback URLs
  successUrl: 'https://dev-kit.smartpay.co/example-pages/checkout-successful',
  cancelUrl: 'https://dev-kit.smartpay.co/example-pages/checkout-canceled',
};

export default lineItems;
