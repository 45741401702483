import { Link } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import cx from 'classnames';
import Switch from 'react-switch';

import { updateUIPairs } from '../redux/ui';
import { updateOptionPairs } from '../redux/option';
import IconDesktop from '../assets/icon-desktop.svg';
import IconMobile from '../assets/icon-mobile.svg';
import Logo from '../assets/logo.svg';

import styles from './Header.module.scss';

const Header = () => {
  const dispatch = useDispatch();
  const view = useSelector((state) => state.ui.view);
  const { isCouponAppliedAutomatically } = useSelector((state) => state.option);

  const setView = (toView) => {
    dispatch(updateUIPairs({ view: toView }));
  };

  return (
    <div className={styles.header}>
      <header>
        <Link to="/" className={styles.logo}>
          <img src={Logo} height={32} alt="Smartpay | Checkout Demo" />
        </Link>
        {!isMobileOnly && (
          <nav className={styles['rwd-control']}>
            <button
              className={cx(
                styles.desktop,
                view === 'desktop' ? styles.active : ''
              )}
              onClick={() => setView('desktop')}
            >
              <img
                src={IconDesktop}
                alt="Desktop View"
                width={20}
                height={20}
              />
            </button>
            <button
              className={cx(
                styles.mobile,
                view === 'mobile' ? styles.active : ''
              )}
              onClick={() => setView('mobile')}
            >
              <img src={IconMobile} alt="Mobile View" width={20} height={20} />
            </button>
          </nav>
        )}
        <a
          href="https://docs.smartpay.co"
          target="_blank"
          rel="noopener noreferrer"
          className={styles['link-to-doc']}
        >
          ドキュメンテーション
        </a>
      </header>
      <div className={styles.options}>
        <div className={styles.option}>
          クーポン自動適用
          <div className={styles.switch}>
            <Switch
              className={styles.trigger}
              checked={isCouponAppliedAutomatically}
              onChange={() => {
                dispatch(
                  updateOptionPairs({
                    isCouponAppliedAutomatically: !isCouponAppliedAutomatically,
                  })
                );
              }}
              offColor="#fd7a64"
              onColor="#7280ff"
              checkedIcon={false}
              uncheckedIcon={false}
              height={21}
              width={38}
              handleDiameter={15}
              activeBoxShadow=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
